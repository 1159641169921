<template>
    <div
        v-if="items.length"
        class="keywords-new-table"
        data-cy="keywords-new-table"
    >
        <div ref="customTooltipWrapper" class="custom-tooltip-wrapper" v-html="tooltipContent" @mouseleave="hideTooltip" />
        <ag-grid-vue
            ref="keywordsTable"
            id="keywords-table"
            class="ag-theme-alpine"
            :style="style"
            :rowData="rowData"
            :columnDefs="columnDefs"
            :gridOptions="gridOptions"
            :defaultColDef="defaultColDef"
            :isFullWidthRow="isFullWidthRow"
            :getRowHeight="getRowHeight"
            suppressRowClickSelection
            @grid-ready="onReady"
            @sortChanged="onSortChanged"
            @columnMoved="onColumnMoved"
            @columnResized="onColumnResized"
            @cellEditingStopped="onCellEditingStopped"
            @row-selected="onRowSelected"
        ></ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import { columns, rankColumns } from './configs/columns.js';
import KwRenderer from './components/custom-renderers/KwRenderer';
import UrlRenderer from './components/custom-renderers/UrlRenderer';
import TagRenderer from './components/custom-renderers/TagRenderer';
import GeoRenderer from './components/custom-renderers/GeoRenderer';
import DateRenderer from './components/custom-renderers/DateRenderer';
import PageRenderer from './components/custom-renderers/PageRenderer';
import RankRenderer from './components/custom-renderers/RankRenderer';
import StartRenderer from './components/custom-renderers/StartRenderer';
import PeriodRenderer from './components/custom-renderers/PeriodRenderer';
import HeaderRenderer from './components/custom-renderers/HeaderRenderer';
import SourceRenderer from './components/custom-renderers/SourceRenderer';
import CategoryRowRenderer from './components/custom-renderers/CategoryRowRenderer';
import SearchResultRenderer from './components/custom-renderers/SearchResultRenderer';
import SearchDetailRenderer from './components/custom-renderers/SearchDetailRenderer';
import SerpFeaturesRenderer from './components/custom-renderers/SerpFeaturesRenderer';
import SearchIntentsRenderer from './components/custom-renderers/SearchIntentsRenderer';
import LastUpdatedRenderer from './components/custom-renderers/LastUpdatedRenderer';
import VisibilityRenderer from './components/custom-renderers/VisibilityRenderer';

export default {
    name: 'KeywordsNewTable',
    components: {
        AgGridVue,
        KwRenderer,
        UrlRenderer,
        TagRenderer,
        GeoRenderer,
        DateRenderer,
        PageRenderer,
        RankRenderer,
        StartRenderer,
        PeriodRenderer,
        SourceRenderer,
        SearchResultRenderer,
        SearchDetailRenderer,
        SerpFeaturesRenderer,
        SearchIntentsRenderer,
        agColumnHeader: HeaderRenderer,
        CategoryRowRenderer: CategoryRowRenderer,
        LastUpdatedRenderer,
        VisibilityRenderer,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            rowData: [],
            columnDefs: [],
            style: 'width: 100%;height: 100vh;',
            defaultColDef: {
                headerCellStyle: { borderRight: '1px solid #e1e8ef' },
                cellStyle: { borderRight: '1px solid #e1e8ef' },
                resizable: true,
                sortable: true,
                lockPinned: true,
                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                    if (
                        nodeA.data.fullWidth ||
                        nodeB.data.fullWidth ||
                        nodeA.data.category !== nodeB.data.category
                    ) {
                        return 0;
                    } else if (nodeA.data.isfav && !nodeB.data.isfav) {
                        return isInverted ? 1 : -1;
                    } else if (!nodeA.data.isfav && nodeB.data.isfav) {
                        return isInverted ? -1 : 1;
                    } else {
                        if (
                            rankColumns.includes(
                                this.getDisplaySetting('sort_by'),
                            )
                        ) {
                            if (valueA === null || valueB === null) {
                                return 0;
                            }

                            valueA = valueA === 0 ? 100 : valueA;
                            valueB = valueB === 0 ? 100 : valueB;
                        }

                        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                    }
                },
            },
            gridOptions: {
                maxBlocksInCache: 50,
                rowSelection: 'multiple',
                sortingOrder: ['desc', 'asc'],
                headerHeight: 33,
                rowHeight: 33,
                suppressPropertyNamesCheck: true,
                suppressRowTransform: true,
                suppressDragLeaveHidesColumns: true,
                fullWidthCellRenderer: 'CategoryRowRenderer',
                undoRedoCellEditing: true,
                enableCellTextSelection: true,
                undoRedoCellEditingLimit: 1,
                getRowClass: params => {
                    const rowData = params.data;

                    return rowData.isfav ? 'favorite' : '';
                },
                isRowSelectable: params => {
                    return !params.data.fullWidth;
                },
            },
            gridApi: null,
            gridColumnApi: null,
            tableColumnSettingsTimer: null,
            lastCellChange: null,
            isMobile:
                window.screen.width <= window.MOBILE_WIDTH_RESOLUTION_PHONE,
        };
    },
    created() {
        this.getTableColumns.map(i => {
            let column = columns[i.name];

            if (
                i.name === this.getDisplaySetting('sort_by') ||
                i.apiAlias === this.getDisplaySetting('sort_by') ||
                (i.name === 'url' &&
                    this.getDisplaySetting('sort_by') === 'domain')
            ) {
                column.sort = this.getDisplaySetting('sort_by_order');
            } else {
                column.sort = null;
            }

            if (i.name == 'kw') {
                column.width = column.width;
                column.minWidth = 140;
            } else {
                column.width = i.width ?? column.width;
            }

            column.sortable = i.sortField ?? true;

            this.columnDefs.push(column);
        });

        this.fillTable();
    },
    mounted() {
        window.addEventListener('resize', this.setFixedWidth);
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setFixedWidth);
        window.removeEventListener('scroll', this.handleScroll);

        if (this.unSubscribe) {
            this.unSubscribe();
        }
    },
    watch: {
        items: {
            handler: 'fillTable',
        },
        isLeftSidebarOpen() {
            setTimeout(() => {
                this.setFixedWidth();
            }, 310);
        },
        getSidebarWidth() {
            this.setFixedWidth();
        },
        getAllWithoutIdsData(to) {
            if (!to.length && this.gridApi) {
                this.gridApi.selectAllFiltered();
            }
        },
        getIsAllCheckboxesSelected(to, from) {
            if (to) {
                this.gridApi?.selectAllFiltered();
            } else {
                this.gridApi?.deselectAllFiltered();
            }
        },
        'keyKeywordsById.length'(to) {
            if (to) {
                if (!this.getLazyLoadMode) {
                    document
                        .querySelector('.ag-header')
                        ?.classList?.add('with-select-all-row');
                }

                if (
                    this.isMobile &&
                    this.$refs.keywordsTable?.$el &&
                    !this.isSearch
                ) {
                    this.$refs.keywordsTable.$el.style.marginTop = '';
                }
            } else {
                document
                    .querySelector('.ag-header')
                    .classList.remove('with-select-all-row');

                if (
                    this.isMobile &&
                    this.$refs.keywordsTable?.$el &&
                    !this.isSearch
                ) {
                    this.$refs.keywordsTable.$el.style.marginTop = '45px';
                }
            }
        },
        getCurrentTooltip(to){
            if(to && this.$refs.customTooltipWrapper && to.content){
                this.$refs.customTooltipWrapper.style.display='block';
                this.$refs.customTooltipWrapper.style.left=`${to.x}px`
                this.$refs.customTooltipWrapper.style.top=`${to.y - 6}px`
            } else if(this.$refs.customTooltipWrapper.style.display === 'block') {
                this.$refs.customTooltipWrapper.style.display = 'none'
            }
        }
    },
    computed: {
        ...mapGetters([
            'getViewkeyView',
            'getOtherSetting',
            'getOriginalItems',
            'getLazyLoadMode',
            'getAllWithoutIdsData',
            'getSelectedAuth',
            'keyKeywordsById',
            'getTableColumns',
            'getSidebarWidth',
            'getProjectByName',
            'getKeywordSetting',
            'getDisplaySetting',
            'getCurrentProject',
            'isLeftSidebarOpen',
            'withoutIdsGetItem',
            'getGlobalSearchQuery',
            'getViewkeyHasChildren',
            'getIsAllCheckboxesSelected',
            "getCurrentTooltip"
        ]),
        getOriginalItemsAndWithoutIdsFilter() {
            const originalItems = this.getOriginalItems;
            const allWithoutIds = this.getAllWithoutIdsData;

            return allWithoutIds.filter(item =>
                originalItems.find(originalItem => originalItem.id === item),
            );
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        tooltipContent() {
            return this.getCurrentTooltip?.content ?? '';
        },
    },
    methods: {
        ...mapActions([
            'fetchKeywords',
            'resetAllKeywords',
            'setAllCheckboxesSelected',
            'setSelectedKeywords',
            'selectAllKeywords',
            'setAllWithoutIds',
            'selectKeywordRow',
            'resetAllWithoutIds',
            'saveKeywordSettings',
            'updateKeywordBaseline',
            'updateKeywordSettings',
            'toggleKeywordSelection',
            'fetchKeywordsFromSearch',
            'toggleKeywordsTableSorter',
            'updateUserDisplaySettings',
            'setCurrentTooltip'
        ]),
        fillTable() {
            let newData = [];

            for (let i = 0; i < this.items.length; i++) {
                if (this.title(i)) {
                    newData.push({
                        fullWidth: true,
                        title: this.title(i),
                        category: this.items[i].category,
                    });
                }

                let rowData = {
                    fullWidth: false,
                    recordNumber: i,
                    ...this.items[i],
                };

                newData.push(rowData);
            }

            if (newData.length <= 5) {
                let height =
                    newData.length * 33 +
                    37 +
                    newData.filter(e => e.fullWidth).length * 13;
                this.style = `width:100%;height:${height}px`;
            }

            this.rowData = Object.freeze(newData);

            setTimeout(() => {
                this.setFixedWidth();
                this.setSelectedRows();
                if (this.getLazyLoadMode) {
                    this.addEvent();
                }
            }, 0);
        },
        onReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            if (this.rowData.length > 5) {
                this.gridApi.setGridOption('domLayout', 'autoHeight');

                if (this.$refs.keywordsTable?.$el) {
                    this.$refs.keywordsTable.$el.style.height = 'unset';
                }
            }

            this.setSelectedRows();
            this.setPercentageWidths();
        },
        horizontalScrollPosition() {
            if (!this.$refs.keywordsTable?.$el) return;

            const tableWidth = this.$refs.keywordsTable?.$el?.clientWidth;

            document.querySelector(
                '.ag-body-horizontal-scroll',
            ).style.width = `${tableWidth - 2}px`;

            const tableLeft = this.$refs.keywordsTable?.$el?.getBoundingClientRect()
                .left;
            document.querySelector(
                '.ag-body-horizontal-scroll',
            ).style.left = `${tableLeft}px`;

            const tableBottom = this.$refs.keywordsTable?.$el?.getBoundingClientRect()
                ?.bottom;
            const scrollerBottom = document
                .querySelector('.ag-body-horizontal-scroll')
                .getBoundingClientRect().bottom;

            if (tableBottom <= scrollerBottom) {
                document.querySelector(
                    '.ag-body-horizontal-scroll',
                ).style.bottom = `${window.innerHeight - tableBottom - 15}px`;
            } else {
                document.querySelector(
                    '.ag-body-horizontal-scroll',
                ).style.bottom = 0;
            }
        },
        handleScroll() {
            if (!this.rowData.length) {
                return;
            }

            this.horizontalScrollPosition();

            const trialBanner = document.querySelector('.trial-period-header');
            const tableTop = document
                .querySelector('.ag-body')
                .getBoundingClientRect().top;

            let offset1 =
                203 +
                (this.isSearch ? (this.isMobile ? -37 : -45) : 0) +
                (this.isMobile ? (this.keyKeywordsById.length ? 40 : 0) : 20) +
                (trialBanner ? 48 : 0);
            let offset2 =
                offset1 -
                (this.isMobile && this.keyKeywordsById.length ? 10 : 0);

            if (tableTop < offset1) {
                let actionBar = document.querySelector(
                    '.dashboard-action-bar:not(.dashboard-action-bar-fixed)',
                );

                if (actionBar) {
                    actionBar.classList.add('dashboard-action-bar-fixed');

                    if (this.isSearch) {
                        document
                            .querySelector(
                                '.dashboard-action-bar-fixed:not(.search-page)',
                            )
                            ?.classList?.add('search-page');
                    }

                    this.setFixedWidth();

                    if (this.isMobile && this.$refs.keywordsTable?.$el) {
                        this.$refs.keywordsTable.$el.style.marginTop = '45px';
                    }
                }
            } else {
                document
                    .querySelector('.dashboard-action-bar-fixed')
                    ?.classList?.remove('dashboard-action-bar-fixed');

                if (this.isMobile && this.$refs.keywordsTable?.$el) {
                    this.$refs.keywordsTable.$el.style.marginTop = '';
                }
            }

            if (tableTop < offset2) {
                let header = document.querySelector(
                    '.ag-header:not(.ag-fixed-header)',
                );

                if (header) {
                    header.classList.add('ag-fixed-header');

                    if (this.isSearch) {
                        document
                            .querySelector('.ag-fixed-header:not(.search-page)')
                            ?.classList?.add('search-page');
                    }

                    this.setFixedWidth();
                    document.querySelector('.ag-body').style.marginTop = `36px`;
                }
            } else {
                document
                    .querySelector('.ag-fixed-header')
                    ?.classList?.remove('ag-fixed-header');
                document.querySelector('.ag-body').style.marginTop = '';
            }
        },
        setFixedWidth() {
            if (!this.rowData.length || !this.$refs.keywordsTable?.$el) {
                return;
            }

            this.isMobile =
                window.screen.width <= window.MOBILE_WIDTH_RESOLUTION_PHONE;

            let tableWidth = this.$refs.keywordsTable?.$el?.clientWidth;
            let actionBar = document.querySelector('.dashboard-action-bar');

            if (actionBar) {
                actionBar.style.width = `${tableWidth}px`;
            }

            document.querySelector('.ag-header').style.width = `${
                tableWidth - 2
            }px`;

            this.horizontalScrollPosition();

            // if (this.gridApi) {
            //     if (window.screen.width <= MOBILE_WIDTH_RESOLUTION_PHONE) {
            //         this.gridApi.applyColumnState({
            //             state: [
            //                 { colId: 'checkbox', pinned: 'left' },
            //                 { colId: 'kw', pinned: 'left' },
            //             ],
            //             defaultState: { pinned: null },
            //         });
            //     } else {
            //         this.gridApi.applyColumnState({
            //             state: [
            //                 { colId: 'checkbox', pinned: 'left' },
            //                 { colId: 'kw', pinned: 'left' },
            //                 { colId: 'url', pinned: 'right' },
            //             ],
            //         });
            //     }
            // }
        },
        setPercentageWidths() {
            if (!this.$refs.keywordsTable?.$el) return;

            this.getTableColumns
                .filter(e => e.name === 'kw')
                .map(i => {
                    let tableWidth = this.$refs.keywordsTable?.$el?.clientWidth;
                    const column = this.gridApi.getColumn(i.name);
                    let width = (tableWidth * i.width) / 100;

                    if (column.actualWidth != width) {
                        this.gridApi.setColumnWidth(column, width);
                    }
                });
        },
        onColumnMoved(event) {
            if (event.finished) {
                const column =
                    event.column?.colDef?.apiAlias ?? event.column?.colId;

                if (!column) {
                    return;
                }

                let order = this.getTableColumns
                    .filter(e => !e.required)
                    .map(e => e.apiAlias);
                const oldIndex = order.indexOf(column);
                const newIndex = event.toIndex - 2;

                let i = newIndex < oldIndex ? -1 : 1;

                for (let key = oldIndex; key != newIndex; key += i) {
                    order[key] = order[key + i];
                }

                order[newIndex] = column;

                this.saveColumnChanges('order', order);
            } else {
                clearTimeout(this.tableColumnSettingsTimer);
            }
        },
        onColumnResized(event) {
            // if (this.lastCellChange !== false) {
            //     this.lastCellChange =
            //         this.lastCellChange === null ? true : false;

            //     if (this.lastCellChange) {
            //         const columns = this.gridApi.getColumns();
            //         const lastColumn = columns[columns.length - 4];
            //         this.gridApi.setColumnWidth(
            //             lastColumn,
            //             lastColumn.colDef.width,
            //         );
            //     }

            //     return;
            // }

            const column = event.column;

            if (!column) return;

            let colId = column.colDef.apiAlias ?? column.colDef.field;
            let newWidth = column.actualWidth - (column.actualWidth % 1);

            if (event.finished) {
                const gridWidth = this.gridApi
                    .getColumnState()
                    .map(col => col.width)
                    .reduce((acc, width) => acc + width, 0);

                if (this.$refs.keywordsTable?.$el?.clientWidth-2 > gridWidth) {
                    const column = this.gridApi.getColumn('url');
                    const newWidth =
                        column.actualWidth +
                        this.$refs.keywordsTable?.$el?.clientWidth -
                        gridWidth-2;
                    this.gridApi.setColumnWidth(column, newWidth);
                }

                let widths =
                    this.getKeywordSetting('table_columns')?.widths ?? {};

                if (colId == 'kw' && this.$refs.keywordsTable?.$el) {
                    let tableWidth = this.$refs.keywordsTable?.$el?.clientWidth;
                    newWidth = Math.round(
                        (100 * column.actualWidth) / tableWidth,
                    );
                }

                if (widths[colId] !== newWidth) {
                    widths[colId] = newWidth;
                    this.saveColumnChanges('widths', widths);
                }
            } else {
                clearTimeout(this.tableColumnSettingsTimer);
            }
        },
        onRowSelected(event) {
            if (this.getIsAllCheckboxesSelected) {
                if (event.node.isSelected()) {
                    this.resetAllWithoutIds(event.data.id);
                } else {
                    this.setAllWithoutIds(event.data.id);
                }
            } else if (event.data.id) {
                this.toggleKeywordSelection({
                    id: event.data.id,
                    force: event.node.isSelected(),
                });
            }
        },
        async onSortChanged() {
            const column = this.gridColumnApi
                .getColumns()
                .filter(c => c.sort)[0];

            let sortBy =
                column.colId === 'url'
                    ? 'domain'
                    : column.colDef.apiAlias ?? column.colId;
            this.toggleKeywordsTableSorter(sortBy);

            if (this.getGlobalSearchQuery) {
                this.fetchKeywordsFromSearch();
            } else {
                const res = await this.fetchKeywords({
                    id: this.getCurrentProject.id,
                    password: this.getCurrentProject.password,
                });

                if (!this.getViewkeyView && res !== null) {
                    await this.updateUserDisplaySettings({
                        setting: 'sort_by',
                        value: this.getDisplaySetting('sort_by'),
                        showMsg: false,
                    });
                    await this.updateUserDisplaySettings({
                        setting: 'sort_by_order',
                        value: this.getDisplaySetting('sort_by_order'),
                    });
                }
            }
        },
        async saveColumnChanges(index, value) {
            let newKeywordTableColumns = this.getKeywordSetting(
                'table_columns',
            );

            if (!newKeywordTableColumns) {
                newKeywordTableColumns = {};
            }

            newKeywordTableColumns[index] = value;
            this.updateKeywordSettings({
                table_columns: newKeywordTableColumns,
            });

            if (!this.getViewkeyView) {
                clearTimeout(this.tableColumnSettingsTimer);
                this.tableColumnSettingsTimer = setTimeout(() => {
                    this.saveKeywordSettings({
                        setting: 'table_columns',
                        value: this.getKeywordSetting('table_columns'),
                    });
                }, 2000);
            }
        },
        isFullWidthRow(params) {
            return params.rowNode.data.fullWidth;
        },
        getRowHeight(params) {
            if (params.node.data.fullWidth) {
                return 46;
            }
        },
        title(index) {
            const previous = this.items[index - 1] || null;
            const keyword = this.items[index] || null;
            let title = null;

            const showViewKeyTitle =
                this.getViewkeyView && this.getViewkeyHasChildren;
            let showGroupTitle = false;

            if (this.getGlobalSearchQuery) {
                showGroupTitle = true;
            } else if (
                !this.getViewkeyView &&
                this.getOtherSetting('subcat') === 'show' &&
                this.getCurrentProject &&
                this.getProjectByName(this.getCurrentProject)?.project.children
            ) {
                showGroupTitle = true;
            }

            if (showViewKeyTitle || showGroupTitle) {
                title =
                    !previous || previous.category !== keyword.category
                        ? keyword.category.replace(
                              '[sub]',
                              '<span class="kw kw-corner-down-right"></span>',
                          )
                        : null;
            }

            return title;
        },
        async onCellEditingStopped(event) {
            if (isNaN(event.value) || event.value < 0 || event.value > 100) {
                this.gridApi.undoCellEditing();
            } else if (event.oldValue != event.value) {
                let kw = this.items.find(i => i.id === event.data.id);
                kw.kwid = kw.id;
                kw[event.colDef.apiAlias ?? event.colDef.field] = event.value;

                try {
                    await this.updateKeywordBaseline(kw);
                } catch (error) {
                    //
                }
            }
        },
        setSelectedRows() {
            if (this.gridApi && this.getSelectedAuth) {
                this.gridApi.forEachNodeAfterFilterAndSort(node => {
                    if (
                        (this.getIsAllCheckboxesSelected &&
                            !this.getAllWithoutIdsData.includes(
                                node.data.id,
                            )) ||
                        this.getSelectedAuth.includes(node.data.id)
                    ) {
                        node.setSelected(true);
                    }
                });
            }
        },
        addEvent() {
            var old_element = document.querySelector('.ag-checkbox-input');

            if (!old_element) return;

            var new_element = old_element.cloneNode(true);
            old_element.parentNode.replaceChild(new_element, old_element);

            document
                .querySelector('.ag-checkbox-input')
                .addEventListener('click', e => {
                    const checkedItems = this.keyKeywordsById;

                    if (checkedItems.length === this.getOriginalItems.length) {
                        this.resetAllKeywords();
                    } else if (this.getIsAllCheckboxesSelected) {
                        const originalItemsIds = this.getOriginalItems.map(
                            item => item.id,
                        );

                        if (!this.getOriginalItemsAndWithoutIdsFilter.length) {
                            if (this.getLazyLoadMode) {
                                this.setAllCheckboxesSelected(false);
                                this.setSelectedKeywords([]);
                            } else {
                                this.setAllWithoutIds(originalItemsIds);
                            }
                        } else {
                            this.resetAllWithoutIds(originalItemsIds);
                        }
                    } else {
                        if (this.getLazyLoadMode) {
                            if (!this.getIsAllCheckboxesSelected) {
                                this.setAllCheckboxesSelected(true);
                            }

                            if (this.getAllWithoutIdsData.length) {
                                this.resetAllWithoutIds();
                            }
                        } else {
                            this.selectAllKeywords();
                        }
                    }
                });
        },
        hideTooltip(){
            this.setCurrentTooltip(null);
        }
    },
};
</script>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';

.select_all_block {
    position: sticky;
    top: 162px;
}

.dashboard-action-bar {
    margin: 0 !important;
    height: 46px !important;
    padding: 10px 0 10px 9px !important;

    .select_all_block {
        position: relative;
        top: unset;
    }
}

.dashboard-action-bar-fixed {
    // top: 141px !important;
    top: 177px !important;


    @media (max-width: 960px) {
        top: 116px !important;

        &.search-page {
            top: 81px !important;
        }
    }
}

.keywords-container_share-view {
    .select_all_block {
        height: 35px;

        @media (max-width: 960px) {
            top: 111px;
        }
    }

    .keywords-new-table {
        .ag-theme-alpine {
            .ag-header.ag-fixed-header {
                top: 111px;

                @media (max-width: 960px) {
                    top: 111px;

                    &.with-select-all-row {
                        top: 146px;
                    }
                }
            }
        }
    }
}
.keywords-new-table {
    min-width: 100%;
    width: max-content;
    overflow: auto;

    .ag-theme-alpine {
        --ag-border-color: #e1e8ef;
        --ag-borders: solid 1px;
        --ag-header-column-resize-handle-display: none;
        --ag-cell-horizontal-padding: 8px;
        --ag-widget-vertical-spacing: 8px;
        --ag-row-hover-color: #e5ecf3;

        .ag-header {
            z-index: 2;

            &.ag-fixed-header {
                position: fixed;
                top: 223px;
                border-top: 1px solid #e1e8ef;

                @media (max-width: 960px) {
                    top: 162px;

                    &.with-select-all-row {
                        top: 197px;
                    }

                    &.search-page {
                        top: 127px;
                    }
                }
            }

            .ag-header-cell {
                color: #63697b;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.2px;
                line-height: 14px;
                user-select: none;
                white-space: nowrap;
                border-right: 1px solid var(--ag-border-color);
            }
        }

        .ag-root-wrapper {
            border-radius: 4px;
        }
        .ag-horizontal-right-spacer,
        .ag-horizontal-left-spacer {
            height: 0;
        }

        .ag-checkbox-input {
            cursor: pointer;
        }

        .ag-checkbox-input-wrapper {
            border-radius: 4px;

            &.ag-indeterminate,
            &.ag-checked {
                background-color: #ffffff;

                &:after {
                    color: rgb(0, 116, 217);
                }
            }
        }

        .ag-row {
            border-bottom: none;

            &:before {
                background-image: none;
            }

            [col-id='checkbox'],
            &.ag-row-even {
                background-color: #ffffff;

                &.favorite {
                    background-color: #fff8ee;
                }
            }

            &.ag-row-odd {
                background-color: #f7f8fa;

                &.favorite {
                    background-color: #fdf2e0;
                }
            }

            &.ag-row-selected:before,
            &.ag-row-hover:before {
                background-color: #e5ecf3;
            }

            .ag-cell {
                display: flex;

                &[col-id='kw'] {
                    padding-left: 0;
                }
                &[col-id='searchIntents'] {
                    span.ag-cell-value {
                        width: 100%;
                        height: 100%;
                    }
                }

                &:not([col-id='kw']):not([col-id='checkbox']):not([col-id='url']):not([col-id='serpFeatures']):not([col-id='near']):not([col-id='gmb']):not([col-id='tagsSplited']) {
                    justify-content: flex-end;
                    padding: 0 15px;

                    span.ag-cell-value {
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                .ag-cell-wrapper {
                    width: 100%;

                    .ag-cell-value {
                        line-height: normal;
                    }
                }
            }

            .ag-cell-value:focus,
            .ag-cell:focus-within {
                border-left: none;
                border-top: none;
                border-bottom: none;
            }

            &.ag-row-hover {
                .keyword-value {
                    width: calc(100% - 140px);

                    &.with-location {
                        width: calc(100% - 140px);
                    }
                }

                .table-cell__actions {
                    display: flex;
                }
            }

            &.ag-full-width-row {
                h4 {
                    font-size: 17px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }

        .ag-sort-indicator-icon {
            .ag-icon {
                height: 25px;

                &:before {
                    content: '\ea2d';
                    font-family: 'Keywords' !important;
                    font-size: 8px;
                    line-height: 16px;
                    color: #63697b;
                    transform: scaleX(0.8) translateX(-1px);
                }

                &.ag-icon-asc {
                    transform: scaleY(-1);
                }
            }
        }



        .ag-body-horizontal-scroll {
            position: fixed;
            bottom: 0;
            opacity: 0;
            z-index: -1;
        }

        .ag-center-cols-viewport {
            scrollbar-width: thin;
            -ms-overflow-style: none;
        }

        .ag-center-cols-viewport::-webkit-scrollbar {
            height: 0;
        }

        .ag-center-cols-viewport::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
    .custom-tooltip-wrapper {
        display: none;
        position: fixed;
        transform: translate(-50%, -100%);
        background-color: white;
        z-index: 999;
        border-radius: 3px;
        padding: 8px;
        box-shadow: 0px 1px 1px 0px #3249621A;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        border: 1px solid #D7D9DF;
        & > div > span {
            font-weight: 400;
        }
        a {
            display: inline-block;
            width: 100%;
            max-width: 200px;
            margin-bottom: -2.7px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &::after {
            content: '';
            position: absolute;
            background-color: white;
            width: 20px;
            height: 20px;
            transform: translateX(-50%) rotate(45deg);
            bottom: -4px;
            left: 50%;
            z-index: -1;
        }
    }
}
</style>
