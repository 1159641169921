export default (i18n, toastr) => {
    return res => {
        if (!res) return;
        
        const {config, data} = res;
        
        if (config.method === 'post' && config.url.includes('/addon/users')) {
            toastr.s(i18n.t('addon-user-added', {user: data.data.id}));
        }

        if (config.method === 'delete' && config.url.includes('/addon/users/')) {
            const user = config.url.split('/addon/users/')[1];
            toastr.s(i18n.t('addon-deleted-user', {user}));
        }

        if (config.method === 'patch' && config.url.includes('/addon/users/')) {
            toastr.s(i18n.t('addon-updated-user', {user: data.data.id}));
        }

        return res;
    };
};