export default (i18n, toastr) => {
    return error => {
        if (!error) return Promise.reject(i18n.t('unexpected-error'));

        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        const { data: { errors }, status } = response;

        switch (config.method) {
            case 'post':
                if (config.url.includes('/groups') && !config.url.includes('/notes')) {
                    const keyOfErrorMessage = status === 422 ? 'detail' : 'message';
                    if (errors.length && errors[0][keyOfErrorMessage]) {
                        let options = { timeOut: 0 };
                        // when the user creates a project, with an existing name
                        if (errors[0].code === '2001') {
                            options = {};
                        }
                        toastr.createToastr({ msg: errors[0][keyOfErrorMessage], type: 'error' }, options);

                    } else {
                        toastr.e(
                            i18n.t('project-add-error')
                        );
                    }

                } else if (config.url.includes('/duplicate') && status === 402) {
                    toastr.e(errors[0]['message']);
                } else if (config.url.includes('/billing-details') && (status === 422 || status === 2001)) {
                    toastr.e(errors[0]['detail']);
                }

                break;

            case 'patch':
                if (config.url.includes('/groups/') && !config.url.includes('/keywords')) {
                    toastr.e(i18n.t('project-update-error'));
                }

                if (config.url.includes('/keywords')) {
                    errors?.forEach(element => {
                        toastr.e(element.detail);
                    });
                }

                break;

            case 'delete':
                if (config.url.includes('/groups/') && !config.url.includes('/notes')) {
                    toastr.e(i18n.t('project-delete-error'));
                }

                break;

            case 'put':
                if (config.url.includes('/groups/') && !config.url.includes('/notes')) {
                    toastr.e(i18n.t('project-archive-error'));
                }

                break;
            case 'get':
                if (config.url.includes('/undo/archive') || config.url.includes('/undo/delete')) {
                    errors?.forEach(element => {
                        toastr.e(element.detail);
                    });
                }
                break;
        }

        return Promise.reject(error);
    };
};
